(function($){
    $(document).ready(function(){
        var intro = $('.intro');
        var intro_content = $('.intro__content');
        if (intro_content.css({maxHeight: 'none'}).height() > intro_content.removeAttr('style').height()) {
            $('<div class="intro__more">Lees meer...</div>').appendTo(intro_content).on('click', function () {
                var originalHeight = intro_content.css({maxHeight: 'none'}).outerHeight();
                intro_content.removeAttr('style').height();
                intro_content.animate({maxHeight: originalHeight}, { complete : function () {
                    intro_content.css({maxHeight: 'none'});
                }});
                $(this).fadeOut();
            });
        }

    });
})(jQuery);