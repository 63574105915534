(function($) {
	var $body = $('body');

	/**
	 * Add class to body when page is scrolled
	 */
	var pageIsScrolled = function() {
		if ($(this).scrollTop() >= 5 ){
			$('body').addClass('body--isScrolled');
		} else {
			$('body').removeClass('body--isScrolled');
		}
	};

	/**
	 * Scroll back to top on .scrollToTop click
	 */
	var scrollToTop = function () {
		$('.scrollToTop').on('click', function(){
			$("html, body").animate({scrollTop : 0}, 700);
			return false;
		});
	};

	var scrollToContent = function () {
		$('.scrollToContent--trigger').on('click', function(){
			$("html, body").animate({scrollTop : $(window).height() - $('.stickyHeader').height()}, 700);
			return false;
		});
	};

	/*----------  Open and close subnavigation on touch devices  ----------*/
	toggleSubNav = function() {
		$('.submenu-trigger').on('click', function(e){
			e.stopPropagation();
			e.preventDefault();
			if($('body').hasClass('device--touch') || $('.navigation--trigger:visible').length) {
				if($(this).parent('.menu-item').hasClass('open-subnav')) {
					$(this).parent('.menu-item').removeClass('open-subnav');
				}
				else {
					$(this).parent().siblings().removeClass('open-subnav');
					$(this).parent('ul').find('li').removeClass('open-subnav');
					$(this).parent('.menu-item').addClass('open-subnav');
				}
			} else {
				$('.menu-item').removeClass('open-subnav');
			}
		});

		$('.navigation ul ul ul .submenu-trigger').on('click', function(e){
			$(this).parent().addClass('open-subnav');
			$(this).parentsUntil('ul').closest('ul').addClass('slidenav');
		});

		$('.back-trigger').on('click', function(e){
			e.stopPropagation();
			e.preventDefault();
			$(this).parents('.slidenav').find('.open-subnav').removeClass('open-subnav');
			$(this).parents('.slidenav').removeClass('slidenav');
		});

		if($('body').hasClass('device--notouch')) {
			$('.navigation ul ul, .navigation ul, .navigation ul li').hover(function(){
				$(this).find('.open-subnav').removeClass('open-subnav');
				$(this).find('.slidenav').removeClass('slidenav');
			});
		}
	};

	/*----------  Open / close navigation button  ----------*/
	var toggleNavigation = function() {

		/* ------ open menu ------ */
		var openMenu = function() {
			$('body').addClass('navigationOpen');
		};

		/* ------ close menu ------ */
		var closeMenu = function() {
			$('body').removeClass('navigationOpen');
		};

		/* -------- open/close menu on icon click -------- */
		$('.navigationTrigger').on('click', function(e){
			e.preventDefault();
			$(this).toggleClass('is-active');
			if($('body').hasClass('navigationOpen')) {
				closeMenu();
			} else {
				openMenu();
			}
		});

		/* -------- open/close menu on key press (esc/m) -------- */
		$(window).on('keydown', function(e){
			if (e.which === 27) {
				closeMenu();
			}
		});
	};

	/**
	 * Smooth Scroll
	 */
	var smoothScroll = function(){
		$('a[href*="#"]').not('[href="#"]').not('[href="#0"]').click(function(event) {
				// On-page links
				if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
					// Figure out element to scroll to
					var target = $(this.hash);
					target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
					// Does a scroll target exist?
					if (target.length) {
						// Only prevent default if animation is actually gonna happen
						event.preventDefault();
						$('html, body').animate({scrollTop: target.offset().top}, 500, function() {
							// Callback after animation
							// Must change focus!
							var $target = $(target);
							$target.focus();
							if ($target.is(":focus")) { // Checking if the target was focused
								return false;
							} else {
								$target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
								$target.focus(); // Set focus again
							}
						});
					}
				}
			});
	};

	/**
	 * Page loaded
	 */
	$(function() {
		scrollToTop();
		pageIsScrolled();
		smoothScroll();
		toggleNavigation();
		scrollToContent();
	});

	/**
	 * Window resized
	 */
	$(window).on('scroll', function(){
		pageIsScrolled();
	});


})(jQuery); // Fully reference jQuery after this point.
