(function ($) {

    /**
     * CategoryMenu Class
     */
    const CategoryMenu = {

        menu : null,

        hideTimer : null,

        scroller: null,

        scrollerElement : null,

        menuTrigger : null,

        activeMenuItemSelector : "li.categoryMenu__item--active",

        /**
         * Init
         */
        init : function () {
            console.log($(this.activeMenuItemSelector).length);
            this.menu = $('.categoryMenu');
            this.menuTrigger = $('a.menuTrigger');

            // Add Event Listeners
            this.menu.find('ul > li > a').on('click', function (e) {
                this.onSubmenuTriggerClick(e.target, e);
            }.bind(this));
            $('.categoryMenu__backTrigger').on('click', this.onBackTriggerClick.bind(this));

            this.menuTrigger.on('click', this.onMenuTriggerClick.bind(this));

        },

        /**
         *
         * @param e
         */
        onMenuTriggerClick : function (e) {
            e.preventDefault();
            this.menu.toggleClass('open');
            this.menuTrigger.toggleClass('open');

            if (this.menu.hasClass('open')) {
                this.menu.niceScroll({
                    autohidemode: "false",
                    horizrailenabled: false,
                    railpadding: { top: 0, right: 0, left: 0, bottom: 0 },
                    cursorcolor: "#dcdcdc",
                    cursorwidth: "19px",
                    cursorborder: 'none',
                    cursorborderradius: 0,
                    background: "white",
                    nativeparentscrolling: false
                });

                this.scroller = this.menu.getNiceScroll()[0];
                this.scrollerElement = $('.nicescroll-rails');

                // $('.categoryMenu__backTrigger').click();
            }


            this.menu.addClass('noAnimation');
            $(this.activeMenuItemSelector).parents('ul').show();
            this.onSubmenuTriggerClick($(this.activeMenuItemSelector).parent().parent().find('> a'));
            setTimeout(function () {
                this.menu.removeClass('noAnimation');
            }.bind(this), 1);
        },

        /**
         * Handle click on menu item arrow
         */
        onSubmenuTriggerClick : function (target, e) {
            var submenu = $(target).parent().find('> ul');
            submenu.find('> li').height('auto');

            if(submenu.length) {
                var niv = $(target).parents('ul').length;
                this.menu.attr('data-niv', niv);
                if (typeof e != "undefined") {
                    e.preventDefault();
                }
                this.menu.getNiceScroll().show();
                this.menu.addClass('categoryMenu--submenuActive');
                // this.menu.find('ul ul').hide();
                this.setHeightToElement(submenu);
                this.scrollerElement.addClass('hidden');
                submenu.show();

                setTimeout(function() {
                    var searchString = '';
                    for (var i = 0; i < niv; i++) {
                        searchString += '> ul > li ';
                        this.menu.find(searchString).height(1);
                    }
                }.bind(this), 225);
            }
        },

        /**
         * Handle click on back trigger
         */
        onBackTriggerClick : function (e) {
            e.preventDefault();
            var niv = this.menu.attr('data-niv');
            var previousElement = $(e.target).closest('ul').parent().parent();
            this.scrollerElement.addClass('hidden');
            this.menu.attr('data-niv', niv - 1);
            previousElement.find('> li').height('auto');
            this.setHeightToElement(previousElement);

            setTimeout(function () {
                $(e.target).closest('ul').hide();
            }, 200);
        },

        /**
         * @param element
         */
        setHeightToElement : function (element) {
            setTimeout(function() {
                this.menu.height(element.height());
                this.menu.getNiceScroll().hide();
            }.bind(this), 1);


            setTimeout(function() {
                this.scroller.resize();
                this.scroller.doScrollTop(0, 0);
                this.scrollerElement.removeClass('hidden');
                this.menu.toggleClass('categoryMenu--withScrollbar', this.scrollerElement.is(':visible'));
            }.bind(this), 230);
        }
    };

    $(document).ready(function () {
        CategoryMenu.init();
    });

})(jQuery);
